<template>
	<div>
		<div
			class="wzBoxS align_items_c"
			v-if="
				searchObj != null &&
				searchObj.column_list != null &&
				searchObj.article_list.length > 0
			"
		>
			<divwzitem_sub_Left
				class="wzItemS pointer"
				v-for="(item, index) in searchObj.article_list"
				:key="index"
				@click="
					$router.push({
						path: '/gw_detail',
						query: { id: item.id },
					})
				"
			>
				<wzitem :item="item"></wzitem>
			</divwzitem_sub_Left>
		</div>
		<!-- <noData v-else></noData> -->
	</div>
</template>

<script>
import wzitem from "./../../components/pc/wzitem.vue";

export default {
	components: { wzitem },

	name: "",
	props: ["searchObj"],
};
</script>

<style scoped>
.wzBoxS {
	margin-top: 10px;
	border-radius: 20px;
	opacity: 1;
	background: #ffffff;
	box-shadow: 6.43px 21.04px 35px 0px rgba(54, 62, 147, 0.051);
}

.wzItemS {
}

@media screen and (max-width: 800px) {
}
</style>
