var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
			_vm.searchObj != null &&
			_vm.searchObj.column_list != null &&
			_vm.searchObj.article_list.length > 0
		)?_c('div',{staticClass:"wzBoxS align_items_c"},_vm._l((_vm.searchObj.article_list),function(item,index){return _c('divwzitem_sub_Left',{key:index,staticClass:"wzItemS pointer",on:{"click":function($event){return _vm.$router.push({
					path: '/gw_detail',
					query: { id: item.id },
				})}}},[_c('wzitem',{attrs:{"item":item}})],1)}),1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }