<template>
	<div class="zh_searchkeyword">
		<div
			class="wzList2"
			v-if="
				searchObj != null &&
				searchObj.activity_list != null &&
				searchObj.activity_list.length > 0
			"
		>
			<div
				class="wzItem2"
				v-for="(item, index) in searchObj.activity_list"
				:key="index"
			>
				<div class="flex align_items_c wzItem2_1">
					<!-- <div class="zxCate flex align_items_c justify_content_c">
                        快讯
                    </div> -->
					<span>{{ item.title }}</span>
				</div>
				<div class="wzItem2_2" v-html="item.content">
					<!-- 比特币至今仍然没有挖干净，剩余的比特币越少，需要的算力就越高。一枚比特币3万美金的电费让普通玩家直呼告辞针对这一问题，纳什币优化了玩法，全部通过合约交互产生， -->
				</div>
				<div class="wzItem2_3">{{ item.start_time }}-{{ item.end_time }}</div>
			</div>
		</div>
		<!-- <noData v-else></noData> -->
	</div>
</template>

<script>
export default {
	name: "",
	props: ["searchObj"],
};
</script>

<style scoped>
.zh_searchkeyword {
	margin-top: 34px;
}

.wzItem2 {
	padding-bottom: 19px;
	border-bottom: 1px solid #f5f5f5;
	margin-top: 18px;
}

.zxCate {
	width: 34px;
	height: 20px;
	background: linear-gradient(184deg, #ffc011 0%, #ff173a 100%);
	border-radius: 6px 0px 6px 0px;
	opacity: 1;
	font-size: 11px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	margin-right: 8px;
}

.wzItem2_1 span {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.wzItem2_2 {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #74747e;
	margin-top: 10px;
}

.wzItem2_3 {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #74747e;
	margin-top: 7px;
}
</style>
