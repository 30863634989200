var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zh_searchkeyword"},[(
			_vm.searchObj != null &&
			_vm.searchObj.column_list != null &&
			_vm.searchObj.article_list.length > 0
		)?_c('div',{staticClass:"zh_searchkeyword_main"},[_c('div',{staticClass:"flex justify_content_sb align_items_c jxBox wzmore"},[_c('div',{staticClass:"jxT"},[_vm._v(" 文章 ")]),_c('div',{staticClass:"more pointer",on:{"click":function($event){return _vm.$emit('changeActiveName', '3')}}},[_vm._v(" 更多 ")])]),_c('div',{staticClass:"wzList2"},_vm._l((_vm.searchObj.article_list),function(item,index){return _c('div',{key:index,staticClass:"wzItem2 pointer",on:{"click":function($event){return _vm.$router.push({
						path: '/gw_detail',
						query: { id: item.id },
					})}}},[_c('wzitem',{attrs:{"item":item}})],1)}),0)]):_vm._e(),(
			_vm.searchObj != null &&
			_vm.searchObj.column_list != null &&
			_vm.searchObj.flash_list.length > 0
		)?_c('div',{staticClass:"steps kxstepssou zh_searchkeyword_main"},[_c('div',{staticClass:"flex justify_content_sb align_items_c jxBox wzmore"},[_c('div',{staticClass:"jxT"},[_vm._v(" 快讯 ")]),_c('div',{staticClass:"more pointer",on:{"click":function($event){return _vm.$emit('changeActiveName', '4')}}},[_vm._v(" 更多 ")])]),_c('el-steps',{staticClass:"wzitem_main",attrs:{"direction":"vertical","active":10}},_vm._l((_vm.searchObj.flash_list),function(item,index){return _c('divwzitem_sub_Left',{key:index,staticClass:"wzItemS pointer",on:{"click":function($event){return _vm.$router.push({
						path: '/gw_detail',
						query: { id: item.id },
					})}}},[_c('wzitem',{attrs:{"item":item}})],1)}),1)],1):_vm._e(),(
			_vm.searchObj != null &&
			_vm.searchObj.column_list != null &&
			_vm.searchObj.column_list.length > 0
		)?_c('div',{staticClass:"author_main zh_searchkeyword_main"},[_c('div',{staticClass:"flex justify_content_sb align_items_c jxBox wzmore"},[_c('div',{staticClass:"jxT"},[_vm._v(" 作者 ")]),_c('div',{staticClass:"more pointer",on:{"click":function($event){return _vm.$emit('changeActiveName', '6')}}},[_vm._v(" 更多 ")])]),_c('div',{staticClass:"wzitem_main author"},_vm._l((_vm.searchObj.column_list),function(item,index){return _c('div',{key:index,staticClass:"authorItem flex align_items_c pointer",on:{"click":function($event){return _vm.$router.push({ path: '/home_wz', query: { id: item.id } })}}},[(item.avatar_img != null && item.avatar_img.length > 0)?_c('img',{key:item.avatar_img,staticClass:"authorItemHead",attrs:{"v-lazy":item.avatar_img,"alt":""}}):_c('img',{staticClass:"authorItemHead",attrs:{"src":"https://zy.metaera.media/user_header.png","alt":""}}),_c('div',{staticClass:"authorItem_r flex direction_column justify_content_sb"},[_c('div',{staticClass:"flex align_items_c"},[(item.nickname)?_c('span',[_vm._v(_vm._s(item.nickname))]):_vm._e(),_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/detail/94.png","alt":""}})]),(item.desc != null && item.desc.length > 0)?_c('div',{staticClass:"authorItem_desc"},[_vm._v(_vm._s(item.desc))]):_c('div',{staticClass:"authorItem_desc"},[_vm._v("全球领先的中文区块链媒体及服务平台")]),_c('div',[_vm._v("共"+_vm._s(item.num)+"篇文章")])])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }